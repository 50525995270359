import React  from 'react';
import './habar.css';
import Carousel from 'react-bootstrap/Carousel';
import ol_1 from './../img/ol_1.jpg';
import slider9 from './../img/slider9.jpg';
import ol_2 from './../img/ol_2.jpg'; 
import ol_3 from './../img/ol_3.jpg';
import ol_4 from './../img/ol_4.jpg'; 
import ol_5 from './../img/ol_5.jpg'; 
import o_m1 from './../img/o_m1.jpeg'; 
import o_m2 from './../img/o_m2.jpeg'; 
import konf from './../img/konf.jpg';
/* import FontAwesome from 'react-fontawesome';
import faStyles from 'font-awesome/css/font-awesome.css'; */
 
    import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk,
        FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber,
         FaGoogle, FaReact, FaPhp, FaSquareFull, FaFacebookF, FaMusic,
          FaEdit, FaGavel, FaAlignLeft, FaCrown, FaBook, FaBookReader, 
          FaChalkboardTeacher, FaCalendarAlt, FaCalendarCheck, FaCalendarPlus,
           FaUserTie, FaBed, FaRegBell, FaBullhorn, FaChessKing, FaUsers, FaAtom, FaLongArrowAltRight} from 'react-icons/fa';
                  
     




    function Habar() {
       

return(
    <div className="habar">
      <div className="item_2" >
    <div className="section_2"  >
 
<div className="s1">
<div className="ico2">
<FaRegBell className="ChalkboardTeacher6"/></div>
<div className="title2">Хабар</div>
<div className="text2">Конфронсҳо, семинарҳои илмӣ ва пешҳимояҳо.
</div>
<div class="section_3">
 <div class="elon">
  <div class="left"> 
 
          <img 
           src={slider9}
           alt="slider 9"
           width="100%"
           height="100%"
                      />
          
        
 </div>
      
 <div class="right"> 
 
        <div className="title">Конференсияи ҷумҳуриявии </div>
        <div className="title"> илмию амалӣ </div>
        <div className="msg0">"Масъалаҳои мубрами технологияҳои иттилоотию иртиботӣ ва таъмини амнияти он" </div>
<div className="msg1">ДМТ. Факултети механикаю математика. Кафедраи ТИИ</div>
<div className="msgline">  </div>
<div className="msg2"> 11-уми марти соли 2025</div>


        
 </div>  
 </div>  
     
       
</div>


</div>
 
<div className="s2">
<div className="ico2">
<FaAtom className="ChalkboardTeacher6"/></div>
<div className="title2">Илм ва инноватсия</div>
<div className="text2">Дастовардҳои илмиву инноватсионӣ</div>
<div class="section_3">
<Carousel>
      <Carousel.Item>
      <div className="habar_1">
          <div className="hb1">
          <img 
           src={ol_1}
           alt="slider 3"
           width="100%"
           height="100%"
                      />
          </div>
        <div className="hb2">
        <div className="title">Олимпиадаи донишҷӯён </div>
        
<div className="msg1">ДМТ. Кафедраи ТИИ</div>
<div className="msgline">  </div>
<div className="msg2"> 23-юми ноябри с.2023</div>


        </div>
        </div>
         
      </Carousel.Item>
      
      <Carousel.Item>
        <div className="habar_1">
          <div className="hb1">
          <img 
           src={ol_2}
           alt="slider 1"
           width="100%"
           height="100%"
                      />
          </div>
        <div className="hb2">
<div className="title"></div>
<div className="msg1">Олимпиада аз фанҳои тахассусии кафедра</div>
<div className="msgline">  </div>
<div className="msg2">Иштироки донишҷӯёни аз курси 1 то 4</div>
         </div>
        </div>
               </Carousel.Item>
               <Carousel.Item>
        <div className="habar_1">
          <div className="hb1">
          <img 
           src={ol_2}
           alt="slider 1"
           width="100%"
           height="100%"
                      />
          </div>
        <div className="hb2">
<div className="title"></div>
<div className="msg1">Олимпиада аз фанҳои тахассусии кафедра</div>
<div className="msgline">  </div>
<div className="msg2">Иштироки донишҷӯёни аз курси 1 то 4</div>
         </div>
        </div>
               </Carousel.Item>
       
    </Carousel>
</div>
</div>


</div>

</div>
      
</div> 
    
    )
  }  export default Habar;