import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './../img/Logo1.png';
import identif from './../img/identif.png';
import adduser from './../img/adduser.png';
import settings from './../img/settings.png';
import update from './../img/update.jpg';
import exit from './../img/exit.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRightToBracket} from '@fortawesome/free-solid-svg-icons';
import tiobe_index from './../img/tiobe_index.png';
import './Header.css';
import React, { useEffect, useState } from 'react'
  
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, 
  FaMailBulk, FaUser, FaUsers, FaUserCircle, FaUserPlus, FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, 
  FaStethoscope, FaCogs, FaUserSecret, FaSearch, FaUniversity, FaSchool, FaSitemap, FaUserTie, FaAngleRight, FaCalendar, FaCalendarCheck, FaCalendarDay, FaCalendarWeek, 
  FaFile, FaFileAlt, FaFileArchive, FaFileCode, FaFileCsv, FaFilePdf, FaFileImport, FaFileWord, FaFileContract, FaFileDownload, 
  FaSistrix, FaSignInAlt, FaCog, FaHome, FaDatabase, FaPhotoVideo, FaAddressBook, FaTable, FaMailchimp, FaAngleDown, FaSign, FaUpload} from 'react-icons/fa';


import { BrowserRouter as Router, Switch, Route, Link}  from 'react-router-dom';
import { oidkafedra } from '../Pages/Oidkafedra';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';
import Modal from './Modal';
import Modalkh from './Modalkh';
import Modalkad from './Modalkad';
import ModalMail from './ModalMail';
import { useRef } from "react";
import {FormGroup, FormLabel, FormControl} from 'react-bootstrap'
import { faSignIn } from '@fortawesome/free-solid-svg-icons';
function NavScrollExample() {
  let navigate = useNavigate();
const [auth, setAuth] = useState('');
const [user, setUser] = useState('');


const ref1 = useRef();
 
const ref2 = useRef();
 
const ref3 = useRef();
 
const ref4 = useRef();


const ref5 = useRef();
const ref6 = useRef();












useEffect(()=>{
  
  var userName = localStorage.getItem('userName');
 

  
  setUser(userName);
  
  if (userName){
 
  ref1.current.style.display = "none";
 
  ref2.current.style.display = "none";
  ref3.current.style.display = "none";
  ref4.current.style.display = "none";
  ref5.current.style.display = "block";
  ref6.current.style.display = "flex";
  }

},
[])

 
const LogOut=()=>{
 
  let keysToRemove = ["email", "userName"];

  keysToRemove.forEach(k =>
      localStorage.removeItem(k))


  navigate(`/Login`);
navigate(`/`);
}


const[openModal, setOpenModal]=useState(false)
const[openModalkh, setOpenModalkh]=useState(false)
const[openModalkad, setOpenModalkad]=useState(false)
const[openModalMail, setOpenModalMail]=useState(false)


  const [fix, setFix]=useState(false);
  function setFixed(){
    if (window.scrollY>=40)
    {
      setFix(true)
    }
    else
    {
      setFix(false)
    }
    }
 window.addEventListener("scroll", setFixed)
 
 



   
  return (
    <div className={fix ? 'navbar_fixed' : 'navbar_all'}> 
    {openModal && <Modal closeModal={setOpenModal} />}
   
    {openModalkh && <Modalkh closeModalkh={setOpenModalkh} />}
    {openModalkad && <Modalkad closeModalkad={setOpenModalkad} />}
    {openModalMail && <ModalMail closeModalMail={setOpenModalMail} />}
 
        <Navbar className="nb"   variant="dark" expand="lg">
         
           
            
     <div  className='menu'>
 
               <div className='dlogo'><img 
                    src={logo}
                    height='38'
                    width='155'
                    className=''
                    alt='logo'
                    />    
                 </div> 
                 
                 
      <ul className='menu_ul'>
        <li > </li>
        <li><span ><NavLink   href="/">Асосӣ</NavLink></span></li>
        <li><span>Маълумот<div className="spico"/></span>
        <ul>
          <li><div className='liline'><a href="http://tnu.tj"> <FaUniversity className="iconsmenu"/>Донишгоҳи мо</a></div></li>
          <li><div className='liline'>  <NavLink   className="blue" href="/oidkafedra"><FaSchool className="iconsmenu"/>Оид ба кафедра</NavLink> </div></li>
          <li><div className='liline'><a href="/#ihtisos"><FaSitemap className="iconsmenu"/>Ихтисосҳо</a></div></li>
          <li><div className='liline'>  <a href="/#omuzgoron"  ><FaUserTie className="iconsmenu"/>Омӯзгорон</a></div> </li>
          <li><div className='liline'><a href="/#samt"><FaAngleRight className="iconsmenu"/>Самти илмӣ</a></div></li>
          <li><div className='liline'><a href="#seminar"><FaCalendar className="iconsmenu"/>Семинарҳо</a></div></li>
          <li><div className='liline'><a href="#jdi"><FaCalendarCheck className="iconsmenu"/>Ҷадвали дарси иловагӣ</a></div></li>
          <li><div className='liline'><a href="#jn"><FaCalendarDay className="iconsmenu"/>Ҷадвали навбатдорӣ</a></div></li>
          <li><div className='liline_no'><a href="#sk"><FaCalendarWeek className="iconsmenu"/>Соати кураторӣ</a></div></li>
        </ul>
        </li>

        <li><span>Маводҳо<div className="spico"/></span>
        <ul>
        
          <li><div className='liline'><NavLink className="blue" href="/mavodho"><FaFilePdf className="iconsmenu"/>Маводи таълимӣ</NavLink></div></li>
           
          <li><div className='liline'><a className="blue"><FaFileImport className="iconsmenu"/>Тести фаннӣ</a></div></li>
          <li><div className='liline'><a className="blue" onClick={()=>{ setOpenModal(true)}}><FaFileDownload  className="iconsmenu"/>Мавзуи кори курсӣ</a></div></li>
          <li><div className='liline'><a className="blue" onClick={()=>{ setOpenModalkh(true)}}><FaFileContract className="iconsmenu"/>Мавзуи кори хатм</a></div></li>
          <li><div className='liline_no'><a className="blue" onClick={()=>{ setOpenModalkad(true)}}><FaFileWord className="iconsmenu"/>Саволномаи КАД</a></div></li>
          
 
           </ul></li>
      </ul>
      <div class="d1">
  <form>
  <input type="text" placeholder="Ҷустуҷӯ..."/>
  <button type="submit"   >  <FaSistrix className="iconssearch"/> </button>
 
  </form>
 
 
  <ul className='userul'>
    <li className='flex'> <img
                   src={settings}
                    height='25'
                    width='25'
                    className=''
                    alt='settings'
    /> 
    <ul className='userli'>
      <li  >    <NavLink ref={ref1}  className="na" href="/login"> <a>Аслиятшиносӣ </a>
      <img
                   src={identif}
                    height='20'
                    width='22'
                    className=''
                    alt='identificatios'
    /> </NavLink> </li> 

<li><NavLink  ref={ref6} className="nav" href="/update"> <a>Навсозӣ</a>
      <img
                     src={update}
                     height='20'
                     width='24'
                     className=''
                     alt='update'
    /> </NavLink> </li>
 <li><NavLink ref={ref2}  className="na" href="/register"> <a>Бақайдгирӣ</a>
      <img
                     src={adduser}
                     height='20'
                     width='22'
                     className=''
                     alt='addUsers'
    /> </NavLink> </li>
    <li>  <span>Истифодабаранда: {user} | <Link to='' onClick={LogOut}> Баромад   </Link> </span>
      </li>
    </ul>
    
    </li>
  </ul>
   
  
                
</div>

      </div>
                <div class="hamburger-menu">
    <input className='inhide' type="checkbox" id="menu_toggle" />
    <label for="menu_toggle" class="menu_btn"> <span></span>
    </label>
    <div className='menu_box'>
     <div className='menu_box_child'>
    <ul class="ul_li">    
      <li ref={ref3}> <NavLink     href="/login"> <a class="menu_item"><FaSignInAlt className="iconsmenu2"/>Аслиятшиносӣ</a></NavLink> 
        </li>    
        <li className='nav' ref={ref5}><NavLink     href="/update"> <a class="menu_item"><FaUsers className="iconsmenu2"/>Навсозӣ</a></NavLink> 
        </li> 
        <li ref={ref4}><NavLink     href="/Register"> <a class="menu_item"><FaUserPlus className="iconsmenu2"/>Бақайдгирӣ</a></NavLink> 
        </li>   
        <li class="sp_item">  <span >Истифодабаранда: {user} | <Link to='' onClick={LogOut}> Баромад   </Link> </span>
      </li>
      <li class="as"><a class="menu_item" href=""></a></li>
      <li><a class="menu_item" href="/"> <FaHome className="iconsmenu2"/>Асосӣ</a></li>
      <li class="pl"> 
        <a class="menu_item" > <input type="checkbox" class="subCat" id="1"   /><label class="chka" for="1">Маълумот   </label>  
        
                  
            
            
            
        <ul class="submenu">
        <li><a href="http://tnu.tj"> <FaUniversity className="iconsmenu2"/>Донишгоҳи мо</a></li>
          <li>  <NavLink   href="/oidkafedra"><FaSchool className="iconsmenu2"/>Оид ба кафедра</NavLink> </li>
          <li><a href="/#ihtisos"><FaSitemap className="iconsmenu2"/>Ихтисосҳо</a></li>
          <li>  <NavLink   ><FaUserTie className="iconsmenu2"/>Омӯзгорон</NavLink> </li>
          <li><a href="/#samt"><FaAngleRight className="iconsmenu2"/>Самти илмӣ</a></li>
          <li><a href="#seminar"><FaCalendar className="iconsmenu2"/>Семинарҳо</a></li>
          <li><a href="#jdi"><FaCalendarCheck className="iconsmenu2"/>Ҷадвали дарси иловагӣ</a></li>
          <li><a href="#jn"><FaCalendarDay className="iconsmenu2"/>Ҷадвали навбатдорӣ</a></li>
          <li><a href="#sk"><FaCalendarWeek className="iconsmenu2"/>Соати кураторӣ</a></li>
           
        </ul></a>
      </li>
      
      <li class="pl"> <a class="menu_item" > <input type="checkbox" class="subCat1" id="2"   /><label class="chka1" for="2">   Маводҳо  </label> 
        
        <ul class="submenu1">
          <li>  <NavLink   href="/mavodho"><FaFilePdf className="iconsmenu2"/>Маводи таълимӣ</NavLink> </li>
          <li><a><FaFileImport className="iconsmenu2"/>Тести фаннӣ</a></li>
          <li><a  onClick={()=>{ setOpenModal(true)}}><FaFileDownload  className="iconsmenu2"/>Мавзуи кори курсӣ</a></li>

          <li><a onClick={()=>{ setOpenModalkh(true)}}><FaFileContract className="iconsmenu2"/>Мавзуи кори хатм</a></li>
          <li><a onClick={()=>{ setOpenModalkad(true)}}><FaFileWord className="iconsmenu2"/>Саволномаи КАД</a></li>
           
        </ul></a>
      </li>
      
       
      <li> <a class="menu_item" href=""><FaPhotoVideo className="iconsmenu2"/>Аксхо</a> </li>
      <li> <a class="menu_item" onClick={()=>{ setOpenModalMail(true)}}><FaPaperPlane className="iconsmenu2"/>Тамос</a> </li>
      <li className='fr2'><form>
  <input type="text" placeholder="Ҷустуҷӯ..."/>
  <button type="submit"   >  <FaSistrix className="iconssearch"/> </button>
 
  </form></li>
    </ul>
    
    </div>
    </div>
  </div>
  
     
  
    </Navbar>

    <div id="tiobe" class="emerge">
              <div class="vsplevsu_anemagen">
              <div class="emergena_tiobe">
              <div class="kamages_designes">
              <h3 class="emergenua">TIOBE Index</h3>
              <a href="#overcas" title="overcas" class="overcas">×</a>
              </div>
              <div class="op_tiobe">
               <p align="justify"><b>Индекси <a href='https://www.tiobe.com'>TIOBE Index</a> </b>(TIOBE programming community index)  - индексe (реестр, руйхат), 
        ки машҳурияти забонҳои барномасозиро дар 
        асоси натиҷаҳои дархостҳои ҷустуҷӯӣ баҳогузорӣ ё арзёбӣ мекунад.</p> 
        <img 
           src={tiobe_index}
           alt="tiobe_index"
           width="100%"
           height="100%"
                      />
              </div>
              </div>
              </div>
                            
        </div>
    
                            
        



    <div id="seminar" class="emerge">
              <div class="vsplevsu_anemagen">
              <div class="emergena_konsuksua">
              <div class="kamages_designes">
              <h3 class="emergenua">Ҷадвали семинарҳо</h3>
              <a href="#overcas" title="overcas" class="overcas">×</a>
              </div>
              <div class="opisaniye_okoshke">
                  <table>
                    <tr>
                      <td>
                        №
                      </td>
                      <td>
                        Сана
                      </td>
                      <td>
                      Номи мавзӯи маърӯза
                      </td>
                      <td>
                        Маърӯзачӣ
                       </td>
                    </tr>
                    <tr>
                      <td>
                        1
                      </td>
                      <td>
                        26.09.2023
                      </td>
                      <td>
                      Рамзгузориҳои муосир
                      </td>
                      <td>
                        Ашӯров Х.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        2
                      </td>
                      <td>
                        15.10.2023
                      </td>
                      <td>
                      Хеш функсияҳои содатарин
                      </td>
                      <td>
                        Шарифзода З.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        3
                      </td>
                      <td>
                        11.11.2023
                      </td>
                      <td>
                      Рамзгузориҳои содатарин
                      </td>
                      <td>
                        Комилов О.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        4
                      </td>
                      <td>
                        20.12.2023
                      </td>
                      <td>
                      Ҳифз дар системаи омилии Windows
                      </td>
                      <td>
                        Рустамова Х.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        5
                      </td>
                      <td>
                        07.01.2024
                      </td>
                      <td>
                      Ҳифзи додаҳо дар смартфонҳо
                      </td>
                      <td>
                        Ҷумаева Н.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        6
                      </td>
                      <td>
                        10.02.2024
                      </td>
                      <td>
                      Ҳифзи додаҳо дар флешкортҳо
                      </td>
                      <td>
                        Мусоев С.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        7
                      </td>
                      <td>
                        15.03.2024
                      </td>
                      <td>
                      Ҳифзи додаҳои шабакаи интернет
                      </td>
                      <td>
                        Қурбонова Н.
                       </td>
                    </tr>
                    
                  </table>
              </div>
              </div>
              </div>
                            
        </div>

        <div id="jdi" class="emerge">
              <div class="vsplevsu_anemagen">
              <div class="emergena_konsuksua">
              <div class="kamages_designes">
              <h3 class="emergenua">Ҷадвали дарси иловагӣ барои донишҷӯ</h3>
              <a href="#overcas" title="overcas" class="overcas">×</a>
              </div>
              <div class="opisaniye_okoshke">
                  <table>
                    <tr>
                      <td>
                        №
                      </td>
                      <td>
                        Номи ҳафта
                      </td>
                      <td>
                      Вақт
                      </td>
                      <td>
                        Омӯзгор
                       </td>
                    </tr>
                    <tr>
                      <td>
                        1
                      </td>
                      <td>
                        Душанбе
                      </td>
                      <td>
                      12:00 - 13:00
                      </td>
                      <td>
                        Ашӯров Х.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        2
                      </td>
                      <td>
                        Сешанбе
                      </td>
                      <td>
                      12:00 - 13:00
                      </td>
                      <td>
                        Шарифзода З.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        3
                      </td>
                      <td>
                        Чоршанбе
                      </td>
                      <td>
                      12:00 - 13:00
                      </td>
                      <td>
                        Комилов О.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        4
                      </td>
                      <td>
                        Панҷшанбе
                      </td>
                      <td>
                      12:00 - 13:00
                      </td>
                      <td>
                        Рустамова Х.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        5
                      </td>
                      <td>
                        Ҷумъа
                      </td>
                      <td>
                      12:00 - 13:00
                      </td>
                      <td>
                        Ҷумаева Н.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        6
                      </td>
                      <td>
                        Шанбе
                      </td>
                      <td>
                      12:00 - 13:00
                      </td>
                      <td>
                        Мусоев С.
                       </td>
                    </tr>
                     
                    
                  </table> </div></div> </div></div>

                  <div id="jn" class="emerge">
              <div class="vsplevsu_anemagen">
              <div class="emergena_konsuksua">
              <div class="kamages_designes">
              <h3 class="emergenua">Ҷадвали навбатдорӣ дар кафедра</h3>
              <a href="#overcas" title="overcas" class="overcas">×</a>
              </div>
              <div class="opisaniye_okoshke">
                  <table>
                    <tr>
                      <td>
                        №
                      </td>
                      <td>
                        Номи ҳафта
                      </td>
                      <td>
                      Вақт
                      </td>
                      <td>
                        Омӯзгор
                       </td>
                    </tr>
                    <tr>
                      <td>
                        1
                      </td>
                      <td>
                        Душанбе
                      </td>
                      <td>
                      12:00 - 13:00
                      </td>
                      <td>
                        Ашӯров Х.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        2
                      </td>
                      <td>
                        Сешанбе
                      </td>
                      <td>
                      12:00 - 13:00
                      </td>
                      <td>
                        Шарифзода З.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        3
                      </td>
                      <td>
                        Чоршанбе
                      </td>
                      <td>
                      12:00 - 13:00
                      </td>
                      <td>
                        Комилов О.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        4
                      </td>
                      <td>
                        Панҷшанбе
                      </td>
                      <td>
                      12:00 - 13:00
                      </td>
                      <td>
                        Рустамова Х.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        5
                      </td>
                      <td>
                        Ҷумъа
                      </td>
                      <td>
                      12:00 - 13:00
                      </td>
                      <td>
                        Ҷумаева Н.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        6
                      </td>
                      <td>
                        Шанбе
                      </td>
                      <td>
                      12:00 - 13:00
                      </td>
                      <td>
                        Мусоев С.
                       </td>
                    </tr>
                     
                    
                  </table> </div></div> </div></div> 
        
                  <div id="sk" class="emerge">
              <div class="vsplevsu_anemagen">
              <div class="emergena_konsuksua">
              <div class="kamages_designes">
              <h3 class="emergenua">Соати кураторӣ</h3>
              <a href="#overcas" title="overcas" class="overcas">×</a>
              </div>
              <div class="opisaniye_okoshke">
                  <table>
                    <tr>
                      <td>
                        №
                      </td>
                      <td>
                        Вақту рӯз
                      </td>
                      <td>
                      Ихтисос ва курс
                      </td>
                      <td>
                        Омӯзгор
                       </td>
                    </tr>
                    <tr>
                      <td>
                        1
                      </td>
                      <td>
                        Душанбе 12:00 - 13:00
                      </td>
                      <td>
                      курси 1  98010101
                      </td>
                      <td>
                       Пиров С.
                       </td>
                    </tr>
                    <tr>
                      <td>
                        2
                      </td>
                      <td>
                        Сешанбе 12:00 - 13:00
                      </td>
                      <td>
                      курси 2  98010101
                      </td>
                      <td>
                        Шарифзода З.  
                       </td>
                    </tr>
                    <tr>
                      <td>
                        3
                      </td>
                      <td>
                        Чоршанбе 12:00 - 13:00
                      </td>
                      <td>
                      курси 3  98010101
                      </td>
                      <td>
                        Ашуров Х.  
                       </td>
                    </tr>
                    <tr>
                      <td>
                        4
                      </td>
                      <td>
                        Панҷшанбе 12:00 - 13:00
                      </td>
                      <td>
                      курси 4  98010101
                      </td>
                      <td>
                        Рустамова Х. 
                       </td>
                    </tr>
                  </table> </div></div> </div></div>  

 
 

                 
    </div>






  );
}







export default NavScrollExample;

 

      
  
  
 

 