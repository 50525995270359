 
import './Footer.css';
import yes from './../img/1.png';
import no from './../img/0.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
import { Mail } from '../Pages/Mail';
import check from './1.txt';
import ot_5 from './../img/ot_5.png';
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaStar, FaCloudscale, FaRadiation} from 'react-icons/fa';
 
  import 'react-circular-progressbar/dist/styles.css';
  import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

  import { ProgressBar } from 'react-bootstrap';
  import 'bootstrap/dist/css/bootstrap.min.css';
 
  import axios from 'axios';
  

function Footer() {
 
  const [disable, setDisable]=useState(true);
  const [plus, setPlus]=useState(0);
  const [minus, setMinus]=useState(0);
  const [box, setBox]=useState('');
  const [ch, setCh]=useState(1);
  //const [minus, setminus]=useState(0);


   

   if (plus==0) {
/* вывод строки из файла     GET str of file   */
fetch("https://dmttii.tj/api/plus.php",{ // куда посилается 
method : 'POST',  // метод POST 
header :{
    'Content-Type': 'application/x-www-form-urlencoded',
},
body : JSON.stringify({action:1})   // передача параметра ввида строки
})
.then(response=> response.text())
.then(response=>{
setPlus(parseInt(response));
 
})

   }
  
 
    

 

 
function increment () 
 {  setCh(ch+1);
  
  if (ch%2===1) {
setPlus(plus+1);
if (plus>0) {setBox('boxsh');}
const url = "https://dmttii.tj/api/in.php";
let fData=new FormData();
fData.append('str', 1);
axios.post(url, fData)
.then(function(response){
console.log(response)

})
.catch((error)=>{
  alert(error)
})}
else 
{
  setPlus(plus-1);
   setBox(''); 
  const url = "https://dmttii.tj/api/in.php";
  let fData=new FormData();
  fData.append('str', -1);
  axios.post(url, fData)
  .then(function(response){
  console.log(response)
  
  })
  .catch((error)=>{
    alert(error)
  })}
 }


/*
 function decrement () 
 {  setCh(ch+1);
  
  if (ch%2===1) {
setPlus(minus);
if (plus>0) {setBox('boxsh');}
const url = "https://dmttii.tj/api/dec.php";
let fData=new FormData();
fData.append('str', 1);
axios.post(url, fData)
.then(function(response){
console.log(response)

})
.catch((error)=>{
  alert(error)
})}
else 
{
  setPlus(minus-1);
   setBox(''); 
  const url = "https://dmttii.tj/api/dec.php";
  let fData=new FormData();
  fData.append('str', -1);
  axios.post(url, fData)
  .then(function(response){
  console.log(response)
  
  })
  .catch((error)=>{
    alert(error)
  })}
 }*/
 

  
 



      return (
        <div className="footer">
          
            <div className="footer_top">
              <div className="lef" id="tamos">
                <h2>ТАМОС</h2> 
                 

                 <table>
              <tr>
                <td className="td_img">
                <FaMapMarkerAlt className="faicon"/>
                 </td>
                <td className="textfooter"> Ҷумҳурии Тоҷикистон, 734042, Душанбе</td>
              </tr>
              <tr>
                <td></td>
                <td className="textfooter">  Буни Ҳисорак, Шаҳраки донишҷӯён
                  (бинои №17) </td>
              </tr>
              <tr>
              <td className="td_img">   <FaRegClock className="faicon"/> </td>
                <td className="textfooter">8:00 - 17:00</td>
              </tr>
              <tr>
              <td className="td_img">  <FaPhoneAlt className="faicon"/> </td>
                   <td className="textfooter">  +992 </td>
              </tr>
              <tr>
              <td className="td_img"> <FaPaperPlane className="faicon_mail"/> </td>
                <td className="textfooter">
               
                <NavLink   href="/Mail"><a class="amail">kafedraitii@gmail.com</a></NavLink>   </td>


             
              </tr>
              <tr>
              <td className="td_img"> <FaBus className="faicon"/> </td>
                <td className="textfooter">М.№-8,10,17,54,А.№-8,10 </td>
              </tr>
            </table>
            </div>
            <div className="righ">
            <div className='footer_title'>
         
         
 


               
<ul>
                  <li className='li'>  
                    
                    
                     <img 
                    src={yes}
                    height='50'
                    width='50'
                    className={box}
                    alt='logo' 
                   onClick={increment}
                     />    
               <div className='vertline'></div>
                 <img 
                    src={no}
                    height='50'
                    width='50'
                    className=''
                    alt='logo'
                  
                    />    
                
                 
                  </li>
                  <li> 
                    <span>{plus}</span>
                    <span> 0 </span>
                     </li>
              
                </ul>

            


          </div>
          

    

          



            </div>

            </div>
            <div className="footer_line">
              <ul>
            <li><p>© 2025 ДМТ. Кафедраи ТИИ</p></li>
                               
              </ul></div>
              
           
       
        

        </div>
   )
}

 
export default Footer;