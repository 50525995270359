import React from "react";
import './oidkafedra.css';
import abc from './../img/abc.png';
import Footer from '../Components/Footer';
 
import Header from '../Components/Header';
 


export const Oidkafedra=()=>(
  <div className="kafedra">
  
    <Header />
    <div className="sc2">
           
 
     
    <div class="txt">
     <h1>Оид ба кафедра</h1>
      <p align="justify">
         
          <img 
            src={abc}
            alt="Муфассал"
           className="abc"
            />Кафедраи технологияҳои
        иттилоотӣ ва иртиботӣ соли 2004
        ташкил шудааст. Мудири аввалини
        кафедра номзади илмхои физикаю математика, дотсент (шодравон)Ашуров А.А. таъин гашта буд.
        Ашуров Абдусамад (08.01.1948, Ҷумҳурии Тоҷикистон, ноҳияи Ҳисор, деҳаи Турдибобо) –
        хатмкардаи ДДТ (1967-1972) ва аспирантураи ДД Маскав (1975-1978) мебошад. Фаъолияти меҳнатиашро дар ДДТ оғоз карда, дар вазифаҳои
        муҳандис-барномасоз (1972-1973), муҳандис-барномасози калон (1973-1974), роҳбари барномасозон
        (1974),
        мудири
        бахш (1980-2001), сарбарномасоз (2001-2004), мудири кафедраи Технологияҳои иттилоотӣ ва иртиботӣ
        (2004 –
        2009) кор кардааст. Аз соли 2007 алочии маорифи Тоҷикистон. Тадқиқоти илмии Ашуров Абдусамад
        ба
        моделсозии математикии соҳаи коркарди металлҳо ва амнияти иттилоотӣ марбутанд. Таҳти роҳбарии
        Ашуров
        Абдусамад зиёда аз 3 системаҳои коркарди иттилоотии корхонаҳо таҳия карда шудаанд. Ашуров
        Абдусамад 23
        асари
        илмӣ таълиф кардааст.
       

      </p>
      <p align="justify"> Сипас сарварии кафедраро номзади илмхои физикаю математика, дотсент

        Ҷумаев Эраҷ Ҳақназарович солхои (2009-2015) бар зимма дошт.
        Э.Ҳ. Ҷумаев хатмкардаи шўъбаи математикаи амалии факултети механикаю математикаи ДМТ (1981) ва
        аспирантураи
        он (1987) мебошад. Соли 2004 рисолаи номзадиро дифоъ намудааст. Вай дорандаи нишони сарисинагии
        “Аълочии
        маорифи халқи Тоҷикистон” буда, бо Ифтихорномаҳои Вазорати маорифи ҶТ (2007), Шӯъбаи маорифи ш.
        Душанбе
        (2006 ва 2007) ва ДДМТ (2005) мукофотонида шудааст.
        Таҳқиқоти илмии ӯ ба ҳалли тақрибии масъалаҳои канорӣ барои баъзе муодилаҳои ҳосилаҳои хусусӣ
        дошта бо
        хати
        сингулярӣ бахшида шудааст.
        Вай муаллифи зиёда аз 40 мақолаи илмӣ ва васоити таълимист, ки “Асосҳои информатика ва
        барномасозӣ дар
        забони Паскал”(2002) аз ҷумлаи онҳост.
        Аз ҷониби Э.Ҳ. Ҷумаев Стандарти давлатии таълимии таҳсилоти олии касбии Ҷумҳурии Тоҷикистон аз
        рўи
        ихтисоси
        1-980101-01 – Амнияти компютерӣ ва Стандарти давлатии таҳсилоти касбии баъд аз муассисаи олии
        таълимӣ(докторантура) аз рўи ихтисоси 1-980101-01 – Амнияти компютерӣ дар ДМТ таҳия шудаанд.</p>
      <div class="lf">
        <div class="lff">
          <div class="atb" align="left">Рӯйхати омӯзгорон</div>

          <div class="tb">
            <table>
              <tr>
                <td>
                  1.  Ашуров Абдусамад н.и.ф.-м., мушовири кафедра
                </td>
              </tr>
              <tr>
                <td>2. Ҷумаев Эраҷ Ҳақназарович н.и.ф.-м., дотсент ва ҳамкори кафедра</td>
              </tr>
              <tr>
                <td>3. Ашӯров Хуршед Мирумарович н.и.и., дотсенти кафедра</td>
              </tr>
              <tr>
              <td>
                4. Нуров Исҳоқбой, д.и.ф.-м., профессори кафедра
              </td>
              </tr>
              <tr>
                <td>5. Қурбонов Кенҷабой н.и.ф.-м., дотсенти кафедра</td>
              </tr>
                          
              <tr>
                <td>6. Рустамова Хуршедабону н.и.и., дотсенти кафедра</td>
              </tr>
              <tr>
                <td>7. Пиров Саидалӣ, муаллими калони кафедра</td>
              </tr>
              <tr>
                <td>8. Комилов Оқил н.и.ф.-м., дотсенти кафедра </td>
              </tr>
              <tr>
                <td>9. Шарифзода Зебонисо н.и.ф.-м., муаллими калони кафедра </td>
              </tr>
              <tr>
                <td>10. Ҷумаева Нигина, муаллими калони кафедра</td>
              </tr>
              <tr>
                <td>11. Қурбонова Наҷотбегим, ассистенти кафедра</td>
              </tr>
                <tr>
                <td>12. Мусоев Суҳайлӣ, ассистенти кафедра</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <p align="justify"> Аз моҳи октябри соли 2015 бошад, сарварии кафедраро номзади илмҳои
        иктисодӣ,
        дотсент

        Ашуров Хуршед Мирумарович (25 ноябри соли 1979 ш. Ӯротеппа (Истаравшани ҳозира) бар зимма
        гириф.
        Ашуров
        Х.М. соли 1996 ба шўъбаи математикаи амалии факултаи механикаю математикаи ДДМТ дохил шуда ва
        соли 2000
        маълумоти бакалаврро гирифтааст. Худи ҳамон сол ба магистратураи ДДМТ дохил шуда, онро соли 2002
        хатм
        намудааст. Аз соли 1999 то 2004 дар литсейи техникии информатика ва техникаи компютерии ш.
        Душанбе
        муаллими
        информатика шуда кор кардааст. Аз моҳи сентябри соли 2004 дар ДДМТ дар вазифаи ассистенти
        кафедраи
        «Технологияҳои иттилоотӣ ва иртиботӣ» ба кор шурӯъ намудааст. Соли 2006 то 2008 дар аспирантураи
        рӯзонаи
        ДДМТ таҳсил кардааст. Баъди хатми аспирантура аз соли 2008 то 2012 дар кафедраи номбурда дар
        вазифаи
        ассистенти кафедра фаъолият намуда соли 2012 рисолаи номзадиашро дар мавзӯъи «Усулҳои
        иқтисодӣ-математикии
        оптимумкунии хароҷотҳо барои ҳимояи иттилоотҳои бонкӣ (дар мисоли “Тоҷикпромбонк”)» дифоъ
        намудааст.
        Натиҷаи
        тадқиқотҳои илмиааш дар бонкҳои тиҷоратӣ барои таъмини бехатарии иттилоот ва ҳифзи иттилоот дар
        мақолаҳои
        нашршудааш инъикоси худро ёфтаанд. Пас аз ҳимояи рисолаи номзадӣ ба вазифаи муаллими калони
        кафедраи
        технологияи иттилоотӣ ва иртиботӣ пешниҳод гардида дар корҳои илмӣ ва ҷамъиятии кафедра ва
        факултет
        фаъолона
        иштирок намудааст ва аз соли 2015 ба вазифаи номзади илмҳои иқтисодӣ, дотсент мушарраф
        гардидааст.
        Ҳамеша
        дониши худро дар самти таъмини бехатарии иттилоот сайқал дода, кӯшиш менамояд, ки ба донишҷӯён
        нозукиҳои
        ин
        касбро хубтару беҳтар омӯзонад. Доимо барои сайқал додани маҳорати касбии худ кӯшиш меварзад.
        Ҳамаи
        супоришу
        вазифаҳое, ки аз тарафи кафедра ва факултет ба ӯ супорида мешаванд, дар вақташ иҷро менамояд. Дар
        раванди
        таълим низ ба дасторвардҳои намоён ноил гардидааст. </p>
        <p>Аз соли 2019 ин ҷониб бошад мудири кафедра номзади илмҳои иқтисодӣ, дотсент Рустамова Хуршедабону Раҳимовна таъин гаштааст.</p>

      <p align="justify"> Омӯзгорони кафедра ба ихтисосҳои донишгоҳӣ дар доираи талаботи нақшаҳои
        амалкунандаи таълимӣ аз фанҳои
        мошинҳои электронии ҳисоббарор, забонҳои барномасозӣ, информатика, асосҳои технологияҳои
        компютерӣ ва
        муосир
        дарс медиҳанд. Онҳо барои ихтисосҳои “Информатика”, “Амнияти компютерӣ”, ”Забони хориҷӣ (хитоӣ).
        Информатика” ва «Забоншиносӣ ва технологияи нави иттилоотӣ» курсҳои тахассусӣ ва амалӣ
        мегузаранд.
        Донишҷӯён
        аз фанҳои тахассусии кафедра корҳои курсӣ ва рисолаҳои дипломӣ менависанд ва дар маҳфилҳои
        кафедра
        ширкат
        меварзанд.
        Дар кафедра 14 нафар омӯзгорон фаъолият дошта, аз онҳо як нафар доктори илм, профессор ва чор
        нафарашон
        номзади илм мебошанд. Устодони кафедра бо самти ҳифзи иттилоот пажӯҳишҳои илмӣ мебаранд. Онҳо
        дар кори
        семинарҳои илмию методӣ, конференсияҳои илмии байналхалқӣ, минтақавӣ, ҷумҳуриявӣ ва донишгоҳӣ
        мунтазам
        бо
        маърӯзаю гузоришҳои илмӣ баромад мекунанд.
        Дар кафедра семинари илмӣ-методии устодону шогирдон ва маҳфилҳои илмӣ-таълимии донишҷӯён амал
        мекунанд,
        ки
        дар онҳо устодони соҳибтаҷриба, мутахассисони ҷавон, аспирантон ва донишҷӯён фаоъолона иштирок
        меварзанд.
        Шогирдони пешқадами кафедра ҳар сол дар олимпиадаҳои фаннии ҷумҳуриявӣ ва озмунҳои илмии
        донишҷӯён
        иштирок
        намуда, ғолиби ин озмунҳо мегарданд.
      </p>
    
    <h1>Оид ба ихтисос</h1>
<p align="justify"> Омӯзгорони кафедра ба ихтисосҳои донишгоҳӣ дар доираи талаботи нақшаҳои
        амалкунандаи таълимӣ аз фанҳои
        мошинҳои электронии ҳисоббарор, забонҳои барномасозӣ, информатика, асосҳои технологияҳои
        компютерӣ ва
        муосир
        дарс медиҳанд. Онҳо барои ихтисосҳои “Информатика”, “Амнияти компютерӣ”, ”Забони хориҷӣ (хитоӣ).
        Информатика” ва «Забоншиносӣ ва технологияи нави иттилоотӣ» курсҳои тахассусӣ ва амалӣ
        мегузаранд.
        Донишҷӯён
        аз фанҳои тахассусии кафедра корҳои курсӣ ва рисолаҳои дипломӣ менависанд ва дар маҳфилҳои
        кафедра
        ширкат
        меварзанд.
        Дар кафедра 14 нафар омӯзгорон фаъолият дошта, аз онҳо як нафар доктори илм, профессор ва чор
        нафарашон
        номзади илм мебошанд. Устодони кафедра бо самти ҳифзи иттилоот пажӯҳишҳои илмӣ мебаранд. Онҳо
        дар кори
        семинарҳои илмию методӣ, конференсияҳои илмии байналхалқӣ, минтақавӣ, ҷумҳуриявӣ ва донишгоҳӣ
        мунтазам
        бо
        маърӯзаю гузоришҳои илмӣ баромад мекунанд.
        Дар кафедра семинари илмӣ-методии устодону шогирдон ва маҳфилҳои илмӣ-таълимии донишҷӯён амал
        мекунанд,
        ки
        дар онҳо устодони соҳибтаҷриба, мутахассисони ҷавон, аспирантон ва донишҷӯён фаоъолона иштирок
        меварзанд.
        Шогирдони пешқадами кафедра ҳар сол дар олимпиадаҳои фаннии ҷумҳуриявӣ ва озмунҳои илмии
        донишҷӯён
        иштирок
        намуда, ғолиби ин озмунҳо мегарданд.
      </p>
</div>

</div>
<Footer />
  </div>
)